import React from "react"

/* Layouts */
import BaseLayout from 'layouts/base.layout'

/* Styling */
import 'components/teaser/blogPosts/blogposts.module.scss'

/* Components */
import SEO from "components/seo"
import HighlightBlogPost from 'components/teaser/blogPosts/highlight'

const BlogPage = (props) => {

  return (
    <BaseLayout>
      <SEO title="Blog" url={ props.location.href } />
      <HighlightBlogPost />
    </BaseLayout>
  )
}

export default BlogPage
