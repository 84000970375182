import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as BlogStyle from './blogposts.module.scss'
import Img from "gatsby-image"

/* Base Component */
import ComponentLayout from 'layouts/component.layout'

const GalleryBlogPosts = () => {
  const data = useStaticQuery(query)

  return (
    <ComponentLayout className={`${BlogStyle.container} grid-flow-row`}>
      {data.allContentfulBlogPosts.edges.map((edge, index) => {
        return (
          <div className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-span-6" key={`blogPostGallery-${index}`}>
            <figure className={`${BlogStyle.image} rounded mb-6`}>
              <Img
                className={`${BlogStyle.gatsbyWrapper}`}
                title={ edge.node.image.title }
                alt={ edge.node.image.title }
                fluid={ edge.node.image.fluid }
                loading="lazy"
                objectFit="cover"
                fadeIn />
            </figure>
            <header>
              <h3 className="text-2xl mb-4">
                <Link to={`/blog/${edge.node.slug}`}>{ edge.node.title }</Link>
              </h3>
              <time>{ edge.node.publishedDate }</time> &middot; <small>{ edge.node.author }</small>
            </header>
          </div>
        )
      })}
    </ComponentLayout>
  )
}

export default GalleryBlogPosts

const query = graphql`
  query {
    allContentfulBlogPosts (
      sort: {
        fields: publishedDate
        order: DESC
      }
      skip: 1
      limit: 4
    ) { edges {
        node {
          title
          slug
          image {
            title
            fluid(maxWidth: 540) {
              ...GatsbyContentfulFluid
            }
          }
          author
          publishedDate(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`
