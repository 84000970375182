import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as BlogStyle from './blogposts.module.scss';
import Img from "gatsby-image"

// Base Component
import ComponentLayout from 'layouts/component.layout'

const StandardBlogPost = () => {
  const data = useStaticQuery(query)

  return (
    <>
      {data.allContentfulBlogPosts.edges.map((edge, index) => {
        return (
          <ComponentLayout className="grid-flow-col py-16" key={`blogPostStandard-${index}`}>
            <div className="col-start-1 col-end-3 md:col-start-1 md:col-span-6 lg:col-span-6 lg:col-start-7">
              <figure className={`${BlogStyle.image} rounded`}>
                <Img
                  className={`${BlogStyle.gatsbyWrapper}`}
                  title={ edge.node.image.title }
                  alt={ edge.node.image.title }
                  fluid={ edge.node.image.fluid }
                  loading="lazy"
                  objectFit="cover"
                  fadeIn />
              </figure>
            </div>
            <div className="col-start-1 col-end-3 md:col-start-1 md:col-span-6 lg:col-start-1 lg:col-end-7 flex flex-col justify-between xl:p-2">
              <div>
                <h3 className="text-2xl mb-4">
                  <Link to={`/blog/${edge.node.slug}`}>{ edge.node.title }</Link>
                </h3>
                <p className="text-standard">
                  { edge.node.teaser }
                </p>
              </div>
              <div className="md:pt-4">
                <time>{ edge.node.publishedDate }</time> &middot; <small>{ edge.node.author }</small>
              </div>
            </div>
          </ComponentLayout>
        )
      })}
    </>
  )
}

export default StandardBlogPost

const query = graphql`
query {
  allContentfulBlogPosts (
    sort: {
      fields: publishedDate
      order: DESC
    }
    skip: 5
  ) { edges {
      node {
        title
        teaser
        slug
        image {
          title
          fluid(maxWidth: 540) {
            ...GatsbyContentfulFluid
          }
        }
        author
        publishedDate(formatString: "DD.MM.YYYY")
        body {
          raw
        }
      }
    }
  }
}
`