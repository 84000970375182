import React from "react"
import { Link, graphql, useStaticQuery } from 'gatsby'
import * as BlogStyle from './blogposts.module.scss';

/* Base Component */
import ComponentLayout from 'layouts/component.layout'
import LinkComponent from 'components/link'

/* BlogComponents */
import GalleryBlogPosts from 'components/teaser/blogPosts/gallery'
import StandardBlogPost from 'components/teaser/blogPosts/standard'

const HighlightBlogPost = () => {
  const data = useStaticQuery(query)

  return (
    <>
      {data.allContentfulBlogPosts.edges.map((edge, index) => {
        return (
          <div className="relative" key={`blogpostHighlight-${index}`}>
            <ComponentLayout className={`${BlogStyle.highlight} grid-flow-row relative z-10 mt-16`}>
              <header className="text-white col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-span-12">
                <h2 className="text-3xl md:text-5xl md:leading-loose mb-4">
                  <Link to={`/blog/${edge.node.slug}`}>{ edge.node.title }</Link>
                </h2>
                <time className="text-lg">{ edge.node.publishedDate }</time> &middot; <small>{ edge.node.author }</small>
              </header>
              <figure className="col-start-1 col-end-3 md:col-start-1 md:col-end-7 lg:col-span-12">
                <img alt={ edge.node.image.title } src={ edge.node.image.file.url } loading="lazy" />
                <figcaption className="text-white p-8 hidden xl:block">
                  <p className="text-2xl mb-8">{ edge.node.teaser }</p>

                  <Link to={`/blog/${edge.node.slug}`}>
                    <LinkComponent text="Weiterlesen" />
                  </Link>
                </figcaption>
              </figure>

              <p className="pb-32 md:pb-24 col-span-2 md:col-span-6 lg:col-span-12 text-standard text-white block xl:hidden">
                { edge.node.teaser }
                <span className="block text-standard pt-8">
                  <LinkComponent text="Weiterlesen" />
                </span>
              </p>
            </ComponentLayout>

            <div className="svg absolute w-full min-w-full">
            <svg className="wave-outer min-w-full" width="100%" height="100%" viewBox="0 0 6000 404">
              <path className="wave" d="M-0.001,535.333l6000,0l0,-352.756c-452.778,-120.385 -837.5,-180.577 -1154.17,-180.577c-1127.07,0 -1155.94,487.919 -1948.49,385.712c-227.895,-29.389 -568.681,-277.608 -980.678,-277.608c-447.397,0 -717.076,281.179 -1063.2,229.962c-409.912,-60.655 -527.655,-198.118 -853.463,-338.066l0,533.333Z"/>
            </svg>
            </div>
          </div>
        )
      })}
      <div className="bg-white pt-8">
        <GalleryBlogPosts />
        <StandardBlogPost />
      </div>
    </>
  )
}

export default HighlightBlogPost

const query = graphql`
  query {
    allContentfulBlogPosts (
      sort: {
        fields: publishedDate
        order: DESC
      }
      limit: 1
    ) { edges {
        node {
          title
          teaser
          image {
            title
            file {
              url
            }
          }
          slug
          author
          publishedDate(formatString: "DD. MMMM YYYY")
        }
      }
    }
  }
`